import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagHeader = `${totalCount} Post${
    totalCount === 1 ? ' ist' : 's sind'
  } mit "${tag}" markiert`;

  return (
    <Layout bodyClass="page-default-single">
      <SEO title={tagHeader} url={location.href} />
      <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
        <h1 className="title">{tagHeader}</h1>
        <ul className="list-group mb-4">
          {edges.map(({ node }) => {
            const { slug } = node.fields;
            const { title } = node.frontmatter;
            return (
              <li className="list-group-item" key={slug}>
                <Link to={slug}>{title}</Link>
              </li>
            );
          })}
        </ul>
        {/*
        This links to a page that does not yet exist.
        You'll come back to it!
      */}
        <Link to="/blog/tags" rel="prev">
          ← Alle Tags
        </Link>
      </div>
    </Layout>
  );
};

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

Tags.getDefaultProps = () => ({
  pageContext: {},
});

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
